import NextLink from "next/link";
import type { Locale } from "../../../types";
import { Vector } from "../../ui/vector";
import type { HeaderReturnVariantProps } from "./header.variants";

interface HeaderBrandProps {
  styles: HeaderReturnVariantProps;
  siteName: string;
  locale: Locale;
}

function HeaderBrand({ styles, siteName }: HeaderBrandProps) {
  return (
    <NextLink
      className={styles.brandLink()}
      href="/"
      tabIndex={-1}
      title={siteName}
    >
      <Vector className={styles.brand()} name="brand" />
    </NextLink>
  );
}

export default HeaderBrand;
