import { AnimatePresence, m } from "motion/react";
import { usePathname } from "next/navigation";
import type { LayoutSideMenuGroup, Theme } from "../../../types";
import { useInitialValue } from "../../../hooks/use-initital-value";
import { useSectionStore } from "../../util/layout-grid/section-context";
import SideMenuVisibleSectionHighlight from "./side-menu-visible-section-highlight";
import SideMenuLink from "./side-menu-link";
import type { SideMenuReturnVariantProps } from "./side-menu.variants";
import NextLink from "next/link";

interface SideMenuGroupProps {
  styles: SideMenuReturnVariantProps;
  className?: string;
  theme: Theme;
  group: LayoutSideMenuGroup;
}

function SideMenuGroup({ styles, className = "", group }: SideMenuGroupProps) {
  const { title, menu } = group;
  const isInsideMobileNavigation = false;

  const [pathname] = useInitialValue(
    [usePathname(), useSectionStore((s) => s.sections)],
    isInsideMobileNavigation
  );

  const isActiveGroup = menu.findIndex((item) => item.link === pathname) !== -1;

  const GroupTitle = () => {
    if (!group?.link) return title;

    const isGroupActive = group.link === pathname;

    return (
      <NextLink
        aria-current={isGroupActive ? "page" : undefined}
        className={
          isGroupActive ? "text-white" : "text-gray-40 hover:text-white"
        }
        href={group.link}
      >
        <span>{title}</span>
      </NextLink>
    );
  };

  return (
    <li className={styles.group({ className })}>
      <m.h2 className={styles.title()} layout="position">
        <GroupTitle />
      </m.h2>
      {menu?.length > 0 && (
        <div className={styles.content()}>
          <AnimatePresence initial={!isInsideMobileNavigation}>
            {isActiveGroup ? (
              <SideMenuVisibleSectionHighlight
                group={group}
                pathname={pathname}
                styles={styles}
              />
            ) : null}
          </AnimatePresence>
          <m.div className={styles.line()} layout />
          <ul className={styles.list()}>
            {menu.map((item) => (
              <m.li className={styles.item()} key={item.id} layout="position">
                <SideMenuLink
                  active={item.link === pathname}
                  href={item.link}
                  label={item.title}
                  openInNewTab={item.openInNewTab}
                  sectionName="side_menu"
                  styles={styles}
                />
              </m.li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
}

export default SideMenuGroup;
