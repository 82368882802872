"use client";

import NextLink from "next-intl/link";
import { Vector } from "../../ui/vector";

function FooterBrand() {
  return (
    <NextLink
      className="inline-flex rounded-xs outline-none mb-4"
      href="/"
      tabIndex={-1}
      title="Music AI"
    >
      <Vector className="h-7 text-white" name="brand" />
    </NextLink>
  );
}

export default FooterBrand;
